.perils {
    overflow-y: auto;
    max-height: calc(100vh - 226px);

    .checkbox {
        display: flex;
        align-items: center;
        padding: 0 10px 0 28px;
    }

    .checkboxDisabled {
        padding: 0 10px 0 28px;
        color: var(--gray-border)
    }

    .checkbox:hover {
        background-color: var(--gray-hover);
        // cursor: pointer;

        .icons {
            display: flex;
            justify-content: space-between;
            background: transparent;
            border-radius: 4px;
            // width: 36px;
            height: 36px;
            z-index: 50;
            color: var(--gray-text);
        }
    }

    .icons {
        display: none;
    }
}