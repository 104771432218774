.modal {
	width: 1000px;
	height: 740px;
}

.tabs {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 30px;
	color: green;
}

.empty {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--gray-text);

	svg {
		width: 64px;
		height: 64px;
		margin-bottom: 15px;
	}
}

.buttons {
	display: flex;
	justify-content: right;
}
