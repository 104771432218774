.distance {
  position: absolute !important;
  top: 20px;
  right: 35px;
  //   z-index: 50;
  height: 36px;
  width: 36px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  // padding: 4px;
  cursor: pointer;

  .icon {
    fill: var(--color-text);
  }

  .iconActive {
    fill: var(--green);
  }
}



.draw {
  margin-right: 5px;
  height: 36px;
  width: 75px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
}