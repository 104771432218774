.container {
  border-radius: 4px !important;
  min-width: fit-content;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;

  .header {
    font-weight: 600;
    font-size: 16px;
    opacity: 0.79;
    color: #333
  }
}