.agendaContent {
  display: flex;
  overflow: hidden;

  .agendaItem {
    flex-shrink: 0;
    border-left: 1px solid var(--gray-border);
    padding-left: 10px;
    width: 300px;
    margin-left: 10px;
  }

  .agendaItem:first-child {
    border-left: none;
    padding-left: 0px;
    margin-left: 0px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  color: #000000de;
  height: 18px;
}

.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #000000de;
}

.legend {
  display: flex;
  align-items: center;
  height: 14px;

  .legendItem {
    flex: 1;
    font-weight: 500;
    font-size: 12px;
    height: 6px;
    color: #0009;
  }

  .legendItem:hover {
    height: 14px;
    outline: 2px solid #000;
    outline-offset: -1px;
    box-shadow: 0 2px 4px #00000061;
  }

}

.legendMutipleItem {
  flex: 1;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 14px;
  color: #0009;
}

.legendMutipleItem:hover {
  outline: 2px solid #000;
  outline-offset: -1px;
  box-shadow: 0 2px 4px #00000061;
}

.icon {
  width: 16px;
  height: 16px;
  color: #0009;
  cursor: pointer;
  font-size: 16px;
}