.location {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 6px;
}

.icon {
  color: var(--gray-text);
  margin-right: 8px;
}

.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}