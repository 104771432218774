.risk {
  width: 780px;

  .tabs {
    display: flex;
    border-bottom: 1px solid var(--gray-border);
  }

  .description {
    font-size: 14px;
    color: var(--gray-text);
    padding: 20px 0;
    // border-bottom: 1px solid var(--gray-border);
  }

  .legend {
    font-weight: 500;
    font-size: 16px;
    padding: 0 0 20px 0;
    border-bottom: 1px solid var(--gray-border);
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    margin: 20px 0;
  }

  .info {
    font-size: 14px;
    color: var(--gray-text);
    margin: 20px 0;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
}