.info {
	width: 600px;

	.text {
		padding: 20px 0 10px 0;
		font-size: 14px;
		line-height: 20px;
		color: var(--gray-text);
	}

	.source {
		margin-right: 4px;
		font-size: 12px;
	}

	.button {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
	}
}
