.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: #0000001f 0 1px 6px, #0000001f 0 1px 4px;
	height: 56px;
	padding-left: 16px;
	z-index: 13;
	position: relative; // this seems to be necessary for the z-index to have effect

	.logo {
		display: flex;
		align-items: center;
		cursor: pointer;
		img {
			width: 114px;
			max-width: none;
		}
	}

	.search {
		display: flex;
		width: 600px;
		font-size: 14px;
		color: var(--gray-text);

		.locations {
			position: absolute;
			top: 47px;
			box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
			z-index: 10;
			width: 600px;
			border-radius: 4px;
			background-color: white;
			font-size: 14px;
			font-weight: 400;
		}

		.location:hover {
			cursor: pointer;
			background-color: var(--gray-hover);
		}

		.nationLocations {
			position: absolute;
			top: 47px;
			box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
			z-index: 10;
			width: min-content;
			border-radius: 4px;
			background-color: white;
			font-size: 14px;
			font-weight: 400;
			margin-left: 35px;
			max-height: 200px;
			overflow: auto;
		}

		.nationLocation {
			padding: 0 5px;
		}

		.nationLocation:hover {
			cursor: pointer;
			background-color: var(--gray-hover);
		}
	}

	.geo {
		width: 300px;
		height: 34px;
		padding: 5px;
		border-radius: 4px;
		color: var(--gray-text);
		font-size: 14px;
	}


	.lang {
		cursor: pointer;
		opacity: 0.5;
		margin: 0 2px;
	}

	.langSelected {
		margin: 0 2px;
	}

	.right {
		width: 450px;
		justify-content: space-around;
		cursor: pointer;

		svg {
			width: 24px;
		}
	}

	.cdwads {
		margin-top: 10px;
		min-width: 112px;
		overflow: auto;
		-webkit-overflow-scrolling: teueh;
		max-height: calc(100vh - 48px);
		border-radius: 4px;
		outline: 0;
		min-height: 64px;
		position: relative;
	}
}

.modal {
	font-size: 14px;
	font-weight: 400;
	color: var(--gray-text);
}

.buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
	padding-top: 16px;
}

.dropdownContent:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.dropdownContent:active {
	background-color: rgba(0, 0, 0, 0.1);
}

.swissSvg {
	width: 14px !important;
	height: 14px;
	margin-right: 3px;
}

.nationSearch {
	display: flex;
	align-items: center;
	border: 1px solid #00000052;
	border-radius: 4px;
	overflow: hidden;
	padding-left: 36px;
	position: relative;
	width: 100%;
	height: 34px;

	.nationSearchSelect {
		width: 100px;
		padding: 5px;
		color: var(--gray-text);
		font-size: 14px;
	}

	.nationSearchInput {
		height: 32px;
		box-sizing: border-box;
		width: 100%;
		background-color: transparent;
		padding-left: 8px;
		font-size: 14px;
		margin-right: 30px;
		color: #999;
	}

	.nationSearchSelect::placeholder,
	.nationSearchInput::placeholder {
		font-size: 14px;
	}

	.nationSearchSelect:focus,
	.nationSearchInput:focus {
		outline: none;
		border: none;
	}

	> span {
		height: 14px;
		border-right: 2px solid #00000052;
	}

	.nationSearchIcon {
		height: 24px;
		width: 24px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 9px;
		color: gray;
	}

	.close {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 5px;
		cursor: pointer;
	}
}

.nationSearch:has(.nationSearchSelect:focus),
.nationSearch:has(.nationSearchInput:focus) {
	border: 2px solid var(--green);
}

.nationSearchSelect:focus,
.nationSearchInput:focus {
	outline: none;
	border: 2px solid var(--green);
}

.termsOfUse {
	width: 820px;
	height: 70vh;
}

.modalBtnBody {
	display: flex;
	justify-content: end;
	align-items: center;
	margin-top: 10px;
}

.customIconButton {
	// box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
	background: #ddede3;
}
