.system {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        font-size: 24px;
        font-weight: 400;
        background-color: var(--gray-background);
        border-bottom: 1px solid #CFCFCF;
    }

    .content {
        display: flex;
        flex-direction: row;
        height: calc(100% - 70px);

        .leftContent {
            width: 250px;
            height: 100%;
            padding: 18px 30px;
            background: #FFFFFF;
            z-index: 1;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.30);
            flex-shrink: 0;

            .menuItem {
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                font-weight: 500;
                color: #666;
                padding: 0 18px;
                cursor: pointer;
                border-radius: 20px;
                margin-bottom: 10px;

                &:hover {
                    background-color: var(--gray-hover);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .active {
                background: rgba(14, 138, 63, 0.08) !important;
                color: #007934;
            }
        }

        .rightContent {
            height: 100%;
            flex: 1;
            padding: 18px;
            background: #FFFFFF;
        }
    }
}