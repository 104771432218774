.sets {
	display: flex;
	flex-direction: column;
	// justify-content: center;
	// align-items: center;
	overflow-x: auto;
	flex: 1;

	.table {
		width: 100%;
		color: var(--gray-text);

		th,
		td {
			padding: 8px;
			font-size: 12px;
		}

		.row {
			height: 43px;

			.radio {
				border: 0px;
				width: 100%;
				height: 20px;
				accent-color: var(--green);
				cursor: pointer;
			}

			.radio:hover {
				accent-color: var(--green);
			}

			.icon {
				height: 20px;
				width: 20px;
				display: inline-block;
				text-align: center;
			}

			.delete {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				display: none;
			}
		}

		.row:hover {
			background-color: var(--gray-hover);
			position: relative;
			cursor: pointer;

			.delete {
				display: inline-block;
			}

			// background: linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #ecf6f0 24px);
			.icons {
				display: flex;
				justify-content: space-between;
				position: absolute;
				background: linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #f5f5f5 24px);
				padding: 4px 10px 3px 15px;
				border-radius: 4px;
				width: 100px;
				right: 0px;
				top: -22px;
				z-index: 50;
				color: var(--gray-text);
			}

			.icons:hover {
				color: #000;
			}
		}
	}

	.pagination {
		position: absolute;
		left: 0;
		right: 15px;
		bottom: 14px;
		height: 56px;
		padding-right: 16px;
		font-size: 12px;
		font-weight: 500;
		line-height: 56px;
		letter-spacing: 0.4px;
		text-align: right;
		color: #0009;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.01) 1.3%,
			rgba(255, 255, 255, 0.07) 5.8%,
			rgba(255, 255, 255, 0.17) 9.7%,
			rgba(255, 255, 255, 0.3) 13.5%,
			rgba(255, 255, 255, 0.45) 17.64%,
			rgba(255, 255, 255, 0.61) 22.5%,
			rgba(255, 255, 255, 0.76) 29%,
			rgba(255, 255, 255, 0.88) 37%,
			rgba(255, 255, 255, 0.96) 48%,
			#fff 61%
		);
	}

	.footer {
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 0.4px;
		color: var(--gray-text);
		padding: 15px 20px;
		border-top: 1px solid var(--gray-border);
	}
}
