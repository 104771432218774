.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  padding: auto;
}

.icon:hover {
  border-radius: 50%;
  background-color: var(--gray-hover);
  cursor: pointer;
}