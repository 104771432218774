.expander {
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid var(--gray-border)
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 56px;
  cursor: pointer;
}

.icon {
  height: 24px;
  width: 24px;
  margin: 1px 0 0 4px;
}

.text {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.count {
  margin-left: auto;
  margin-right: 16px;
  font-size: 12px;
  font-weight: normal;
  color: #999;
}

.children {
  margin: -10px 0 10px;
  color: var(--gray-text);
}