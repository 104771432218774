.buttonPrimary {
  height: 36px;
  width: inherit;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  --bg-color: var(--green);
  --text-color: white;
  background-color: var(--bg-color);
  color: var(--text-color);
  padding: 0 16px;
  border-radius: 4px;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  font-size: 14px;
}

.buttonPrimary:hover {
  cursor: pointer;
}

.buttonSecondary {
  @extend .buttonPrimary;
  --bg-color: white;
  --text-color: var(--green);
  box-shadow: none;
}

.buttonSecondary:hover {
  background-color: #0000000a;
  cursor: pointer;
}

.buttonOutlined {
  @extend .buttonSecondary;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  border: 1px solid var(--green)
}

.buttonOutlined:hover {
  background-color: var(--green-hover);
  cursor: pointer;
}

.buttonDisabled {
  @extend .buttonPrimary;
  --bg-color: #0000001f;
  --text-color: #00000042;
  box-shadow: none;
}

.buttonDisabled:hover {
  cursor: default;
}