.modal {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	height: 170px;
	width: 500px;

	.import {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		height: 320px;
		width: 552px;
		border-radius: 4px;
		border: 2px dashed rgba(0, 0, 0, 0.38);
		background-color: var(--gray-background);
		cursor: pointer;

		.upload {
			display: flex;
			flex-flow: column;
			align-items: center;
			margin: 72px 0;

			.icon {
				height: 64px;
				width: 64px;
				fill: #00000061;
			}

			.text1 {
				font-weight: 400;
				font-size: 16px;
				margin: 10px 0;
				color: rgba(0, 0, 0, 0.6);
			}

			.text2 {
				font-weight: 400;
				font-size: 12px;
				color: rgba(0, 0, 0, 0.6);
			}
		}

		.hint {
			font-weight: 400;
			font-size: 12px;
			margin: 2px 0;
		}

		.link {
			font-weight: 400;
			font-size: 12px;
			color: var(--green);
			z-index: 50;
		}
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
	padding-top: 16px;
}
