.layers {
  height: calc(100vh - 58px);
  width: 400px;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
  z-index: 11;

  .header {
    font-size: 24px;
    line-height: 28px;
    background-color: var(--gray-background);


    .title {
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
    }

    .search {
      width: 260px;
      padding: 0 16px;
      margin-bottom: 16px;
    }

    .menu {
      display: flex;
      border-bottom: 1px solid var(--gray-border);
    }
  }

  .loading {
    height: calc(100% - 170px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}