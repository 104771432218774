.locationInfo {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--gray-text);
  padding-bottom: 15px;
  border-bottom: 1px solid var(--gray-border);
}

.singleHazardInfo {
  position: absolute;
  height: 24px;
  width: 35px;
  right: 20px;
  overflow-y: visible;
  cursor: pointer;
}

.resultsSingle {
  max-height: calc(100vh - 425px);
  overflow-y: auto;
}

.resultsMultiple {
  height: calc(100vh - 445px);
  overflow-y: auto;
}