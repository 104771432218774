.single {
	display: flex;
	flex-flow: column;
	height: calc(100vh - 56px);
	width: 400px;
	justify-content: space-between;
	background-color: #fff;
	box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		font-size: 16px;
		border-bottom: 1px solid var(--gray-border);

		.icon {
			height: 36px;
			width: 36px;
			padding: 6px;
			fill: var(--gray-text);
		}

		.icon:hover {
			border-radius: 50%;
			background-color: var(--gray-hover);
			cursor: pointer;
		}
	}

	.info {
		display: flex;
		padding: 16px;
		border-bottom: 1px solid var(--gray-border);

		.image {
			width: 93px;
			height: 93px;
			background-size: cover;
			background-position: center;
			background-color: #0e8a3f14;
		}

		.address {
			display: flex;
			flex-flow: column;
			justify-content: space-between;
			margin: 0% 2%;
			width: 260px;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.4px;
			color: var(--gray-text);

			.province {
				color: #333;
				margin-left: 30px;
			}
		}
	}

	.buttons {
		display: flex;
		width: 100%;
		padding: 12px 24px;
		border-top: 1px solid var(--gray-border);
	}

	.buttonAnalyse {
		background: #007934 !important;
	}

	.buttonAnalyse:hover {
		background: #267d4b !important;
	}

	.buttonAnalyse:active {
		background: #267d4b !important;
		box-shadow: 0 0px 4px 0 rgba(38, 125, 75, 0.5);
	}

	.buttonClose {
		background: #007934;
        color: #fff;
	}
}
