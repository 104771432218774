.tab {
  display: flex;
  align-items: center;
  height: 48px;
  background-color: transparent;
  padding: 0 12px;
  font-size: 14px;
  font-stretch: 100%;
  text-transform: uppercase;
  color: #333;
  opacity: 1;
  padding: 0 12px;
  letter-spacing: 1.25px;
  line-height: 16px;
}

.tab:hover {
  cursor: pointer;
  background-color: var(--gray-hover);
}

.tabActive {
  @extend .tab;
  color: var(--green);
  border-bottom: 2px solid var(--green);
}