.sidebar {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
  height: calc(100vh - 56px);
  width: 88px;
  padding: 12px 0;
  z-index: 12;
  background-color: white;
}

.item {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 88px;
  border-left-width: 5px;
  border-color: transparent;
  cursor: pointer;
  --color: var(--gray-text);
  --color-hover: black;
  color: var(--color);
}

.itemActive {
  @extend .item;
  --color: var(--green);
  border-color: var(--color);
  --color-hover: var(--color);
}

.icon {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
}

.image {
  height: 24px;
  width: 24px;
  fill: var(--color);
}

.image:hover {
  fill: var(--color-hover);
}

.text {
  color: var(--color);
  font-size: 12px;
}