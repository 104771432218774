.options {
  display: flex;
  width: fit-content;
  border-radius: 4px;
  border: 1px solid var(--gray-border);
  text-transform: uppercase;
  font-size: 14px;
  color: #333;

  .button {
    border-color: grey;
    font-weight: 500;
    text-decoration-color: #475569;
    padding: 10px 20px;
    cursor: pointer;
  }

  .button:hover {
    background-color: var(--gray-hover)
  }

  .buttonActive {
    @extend .button;
    text-decoration-color: var(--color);
    background-color: #0e8a3f14;
    color: var(--green);
  }

  .divider {
    border-right: 1px solid var(--gray-border);
  }
}