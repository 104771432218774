.checkbox {
  display: flex;
  align-items: center;
  height: 36px;
  background-color: transparent;
  cursor: pointer;
}

.input {
  appearance: none;
  outline: 0;
  height: 16px;
  width: 16px;
  border: 1px solid black;
  border-radius: 2px;
  margin: auto 8px auto 0;
  margin-left: 4px;
  cursor: pointer;
}

.input:checked {
  background: var(--green);
  border-color: var(--green);
  color: white;
  margin-left: 4px;
}

.input:after {
  content: url("../../assets/checkbox_tick.svg");
  position: relative;
  border-width: 0;
  display: none;
}

.input:checked:after {
  display: block;
}

.text {
  font-size: 14px;
  font-style: normal;
}