.modal {
	display: flex;
	flex-flow: column;
	height: 500px;
	width: 700px;

	.info {
		margin: 30px 0 10px 10px;
		font-size: 12px;
	}
}

.buttons {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
	padding-top: 16px;

    button {
        margin-left: 10px;
    }
}
