.agendasM {
  position: absolute;
  left: 420px;
  bottom: 40px;
  z-index: 1;
}

.agendasS {
  @extend .agendasM;
  left: 20px;
}

.agendasL {
  @extend .agendasM;
  left: 820px;
}

.legendType {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.legendType>div {
  padding: 6px;
}

.legendType>div:hover {
  background-color: #0000000d;
}

.legendClose {
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
  width: 240px;
  max-height: 60vh;
  height: fit-content;
  background-color: white;
  position: absolute;
  bottom: 48px;
}

.legendOpen {
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
  background-color: white;
  position: absolute;
  left: 48px;
  top: 0;
  display: flex;
  height: 36px;
  max-width: calc(100% - 48px);
}