.zoom {
  position: absolute;
  bottom: 110px;
  right: 160px;
  z-index: 50;
  height: 32px;
  width: 32px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 3px;
  cursor: pointer;

  .icon {
    fill: var(--color-text);
    width: 25px;
    height: 25px;
  }
}