.legendItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.lagend {
    width: 100%;
    height: 5px;
    border: 0px solid grey;
}

.active {
    height: 10px;
    box-shadow: 0px 1px 1px grey;
}

.tooltip::before {
    overflow: hidden;
    text-align: start;
    white-space: wrap;
}