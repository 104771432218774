.analyse {
  width: 780px;

  .header {
    display: flex;
    align-items: center;
    height: 58px;
    border-radius: 4px;
    background-color: var(--gray-background);
    border: 1px solid var(--gray-border);
    margin: 10px 0;

    .image {
      height: 40px;
      width: 40px;
      margin: 10px;
      fill: var(--gray-text)
    }
  }
}