.mapXS {
  height: calc(100vh - 58px);
  width: calc(100vw - 1290px);
}

.mapS {
  height: calc(100vh - 58px);
  width: calc(100vw - 890px);
}

.mapM {
  height: calc(100vh - 58px);
  width: calc(100vw - 490px);
}

.mapL {
  height: calc(100vh - 58px);
  width: calc(100vw - 90px);
}