.input {
  height: 34px;
  border: 1px solid #00000052;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  padding-left: 8px;
}



.inputIcon {
  @extend .input;
  padding-left: 34px;
  font-size: 14px;
  color: #999;
}

.input:focus {
  outline: none;
  border: 2px solid var(--green);
}

.input::placeholder {
  font-size: 14px;
  // transform: translateY(-4px);
}

.closeInput {
  padding-right: 30px;
}

.icon {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 9px;
  color: gray
}

.close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  height: 24px;
}