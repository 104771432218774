.locations {
	height: calc(100vh - 56px);
	width: 400px;
	display: flex;
	flex-direction: column;
	box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;

	.header {
		font-size: 24px;
		line-height: 28px;
		background-color: var(--gray-background);
		border-bottom: 1px solid var(--gray-border);

		.title {
			font-weight: 400;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px;
		}

		.search {
			width: 260px;
			padding: 0 6px;
		}

		.switch {
			cursor: pointer;

			svg {
				width: 20px;
				height: 20px;
				fill: #0009;
			}

			svg:hover {
				fill: var(--color-hover);
			}
		}
	}

	.loading {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}
