.modal {
	display: flex;
	flex-flow: column;
	// height: 300px;
	width: 700px;
    // color: #666;
    // font-size: 14px;

	.info {
		margin: 30px 0 10px 10px;
		font-size: 12px;
	}

	.icon {
		margin-right: 10px;
	}

	.text {
		line-height: 30px;
	}

	.example {
		margin-top: 10px;
		padding: 10px;
		border-radius: 3px;
		background: #eee;
		font-size: 12px;
	}
}
