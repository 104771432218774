.multiple {
  display: flex;
  flex-flow: column;
  height: calc(100vh - 56px);
  width: 400px;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    font-size: 16px;
    border-bottom: 1px solid var(--gray-border);

    .icon {
      height: 36px;
      width: 36px;
      padding: 6px;
      fill: var(--gray-text)
    }

    .icon:hover {
      border-radius: 50%;
      background-color: var(--gray-hover);
      cursor: pointer;
    }
  }

  .image {
    width: 93px;
    height: 93px;
    background-size: cover;
    background-position: center;
    background-color: #0e8a3f14;
    margin-right: 8px;
    border-radius: 4px;
  }

  .address {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: var(--gray-text);
  }

  .locationCount {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
    padding: 12px;
    border-bottom: 1px solid var(--gray-border);
    border-top: 1px solid var(--gray-border);
  }

  .info {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid var(--gray-border);

    .image {
      width: 93px;
      height: 93px;
      background-size: cover;
      background-position: center;
      background-color: #0e8a3f14;
    }

    .province {
      color: #333;
      margin-left: 30px;
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    padding: 12px 24px;
    border-top: 1px solid var(--gray-border);
  }
}