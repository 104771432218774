.import {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 125px);
	background-color: var(--gray-background);

	.button {
		display: flex;
		flex-flow: column;
		align-items: center;
		padding: 16px;
		background-color: #e7e7e7;
		cursor: pointer;

		.icon {
			height: 64px;
			width: 64px;
			fill: #00000061;
		}

		.text {
			width: 121px;
			text-align: center;
			margin: 4px 0;
			font-weight: 400;
			font-size: 14px;
		}
	}
}
